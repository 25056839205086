<template>
    <!-- 热搜词 -->
    <div class="HotTerms" v-show="status">
        <div class="swiper-container" id="sw" ref="sw">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="el in list" :key="el.id">
                    {{ el.key_word }}
                </div>
            </div>
        </div>
        <div class="close" @click="status = false">X</div>
    </div>
</template>

<script>
import axios from "axios";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
    data() {
        return {
            list: [],
            status: true,
            swiper: {},
        };
    },
    async created() {
        let { data } = await axios.get("/api/key_word/float");
        this.list = data;
        this.$nextTick(() => {
            this.swiper = new Swiper("#sw", {
                slidesPerView: "auto",
                mousewheel: true,
                spaceBetween: 5,
                freeMode: true,
                on: {
                    click: e => {
                        let txt = e.target.innerText;
                        this.$router.push("/SearchPage?val=" + txt);
                    },
                },
            });
        });
    },
    methods: {},
};
</script>

<style lang="less" scoped>
.HotTerms {
    position: fixed;
    z-index: 1800;
    left: 50%;
    bottom: 38px;
    transform: translateX(-50%);
    width: 90%;
    max-width: calc(2560px * 0.9);
    height: 30px;
    text-align: center;
    .swiper-container {
        height: 30px;
        line-height: 30px;
        .swiper-slide {
            background-color: rgba(0, 0, 0, 0.2);
            padding: 0 10px;
            border-radius: 20px;
            color: white;
            cursor: pointer;
            display: inline-block;
            // margin-left: 10px;
            width: auto;
            &:hover {
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
    }
    .close {
        position: absolute;
        z-index: 9;
        right: 0;
        top: 0;
        transform: translateX(120%);
        background-color: rgba(0, 0, 0, 0.2);
        padding: 0px 4px;
        border-radius: 20px;
        width: 32px;
        height: 24px;
        line-height: 24px;
        margin-left: 10px;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        transition: 0.3s;
        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}
</style>
