<template>
    <div class="TopSearch" :class="status ? 'filter' : ''" ref="top_search" @mousewheel="hidden" @click="hidden">
        <div class="SearchContent" :class="status ? '' : 'delay'" @click.stop>
            <div class="searchBox an">
                <div class="icon_search" title="搜索">
                    <img src="https://iv.okvu.cn/vugw/img/vu818.png" />
                </div>
                <input
                    type="text"
                    v-model="searchVal"
                    ref="ipt"
                    @input="ipt"
                    @focus="focus"
                    @blur="blur"
                    @keydown.enter="clickSearch"
                    :class="selectList.length && isFocus ? 'selecting' : ''"
                />
                <button @click="clickSearch">威有一下</button>
                <div class="select" v-show="selectList.length && isFocus">
                    <p v-for="el in selectList" :key="el.title" @click="selectClick(el)">
                        {{ el.title }}
                    </p>
                </div>
                <div class="swiper-container UpDown" v-show="swStatus">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="el in gx" :key="el.id">
                            {{ el.key_word }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="wantSo an">
                <p><b>都在搜</b></p>
                <div>
                    <span
                        v-for="el in rs"
                        :key="el.id"
                        @click="
                            $router.push(`/SearchPage?val=${el.key_word}`);
                            status = false;
                        "
                    >
                        {{ el.key_word }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
    data() {
        return {
            status: false,
            rs: [],
            gx: [],
            swiper: {},
            // 上下滚动显示状态
            swStatus: true,
            // 是否聚焦
            isFocus: false,
            searchVal: "",
            selectList: [],
            timer: null,
        };
    },
    async created() {
        // 热搜数据
        let { data: rs } = await axios.get("/api/key_word/show");
        // 共享数据
        let { data: gx } = await axios.get("/api/key_word/float");
        this.rs = rs;
        this.gx = gx;
        this.$nextTick(() => {
            this.swiper = new Swiper(".UpDown", {
                direction: "vertical",
                loop: true,
                autoplay: true,
                // 停留时长
                delay: 3000,
            });
        });
    },
    methods: {
        show() {
            this.status = true;
        },
        hidden() {
            this.status = false;
        },
        focus() {
            this.swStatus = false;
            this.swiper.autoplay.stop();
            this.isFocus = true;
        },
        blur() {
            setTimeout(() => {
                this.isFocus = false;
            }, 100);
            if (!this.searchVal) {
                this.swStatus = true;
                this.swiper.autoplay.start();
            }
        },
        // 点击搜索
        clickSearch() {
            let val = "";
            if (this.searchVal) {
                val = this.searchVal;
            } else {
                // 如果没有滚动的关键字
                if (this.gx.length == 0) {
                    val = "";
                    return;
                } else {
                    val = this.gx[this.swiper.realIndex].key_word;
                }
            }
            this.$router.push(`/SearchPage?val=${val}`);
            this.status = false;
        },
        ipt() {
            clearTimeout(this.timer);
            this.timer = setTimeout(async () => {
                const { data } = await axios.get("api/project/keywordregexp", { params: { keyword: this.searchVal } });
                this.selectList = data;
            }, 400);
        },
        // 点击搜索出的项
        selectClick(el) {
            this.selectList = [];
            this.status = false;
            this.searchVal = "";
            this.$router.push(`/case?id=${el.second_id}&fuid=${el.first_id}`);
        },
    },
    watch: {
        status(newVal) {
            this.$emit("theAnim", newVal);
        },
    },
};
</script>

<style lang="less" scoped>
.TopSearch {
    position: fixed;
    top: 85px;
    left: 0;
    width: 100%;
    height: 100%;
    // height: calc(100vh - 85px);
    backdrop-filter: blur(0px);
    transition: all 0.2s;
    z-index: -1;
    visibility: hidden;
    text-align: left;
    color: #7781f1;
    .SearchContent {
        // background: linear-gradient(45deg, #7781f1, #acb2f8);
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 10px 10px 1px gainsboro;
        // background: #7781f190;
        height: 0%;
        transform: scaleY(0);
        transform-origin: 50% 0;
        transition: all 0.2s;
        padding-top: 10px;
        .an {
            transition: all 0.2s;
            opacity: 0;
            max-width: 1280px;
            margin: 0 auto;
        }
        .searchBox {
            display: flex;
            align-items: center;
            height: 50px;
            box-sizing: border-box;
            position: relative;
            width: 100%;
            // overflow: hidden;
            .icon_search {
                position: absolute;
                width: 40px;
                height: 40px;
                left: 10px;
                top: calc((50px - 40px) / 2);
                overflow: hidden;
                img {
                    position: absolute;
                    top: -564px;
                    left: -468px;
                    width: 1267px;
                }
            }
            input {
                width: 83%;
                border: 1px solid #7781f1;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                border-right: none;
                background-color: transparent;
                height: 100%;
                font-size: 16px;
                padding: 0 60px 0;
                // border-radius: 10px;
                // &:focus {
                //     border: 1px solid white;
                // }
            }
            // 输入框的选择中样式
            .selecting {
                border-bottom-left-radius: 0px;
                border-bottom-color: rgba(0, 0, 0, 0.18);
            }
            .select {
                width: calc(83% + 1px);
                position: absolute;
                // height: 300px;
                background-color: white;
                border: 1px solid #7781f1;
                border-top: none;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                max-width: 1280px;
                top: 50px;
                overflow: hidden;
                p {
                    padding: 0 30px 0;
                    font-size: 16px;
                    height: 34px;
                    line-height: 34px;
                    cursor: pointer;
                    color: black;
                    &:hover {
                        background-color: #ececec;
                        color: #7781f1;
                    }
                }
            }
            button {
                height: 50px;
                line-height: 50px;
                // border-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                border: 1px solid #7781f1;
                text-align: center;
                background-color: transparent;
                padding: 0 20px;
                font-size: 16px;
                background-color: #7781f1;
                color: white;
                cursor: pointer;
                box-sizing: border-box;
                width: 10%;
                &:hover {
                    background-color: white;
                    color: #7781f1;
                }
            }
            .UpDown {
                position: absolute;
                left: 60px;
                top: 0;
                z-index: -1;
                font-size: 16px;
                color: black;
                height: 50px;
                line-height: 50px;
            }
        }

        .wantSo {
            & > div {
                display: flex;
                // flex-direction: column;
                flex-wrap: wrap;
                line-height: normal;
                span {
                    margin-right: 30px;
                    margin-bottom: 10px;
                    width: auto;
                    cursor: pointer;
                    color: black;

                    &:hover {
                        color: #7781f1;
                    }
                }
            }
        }
    }
}
.filter {
    backdrop-filter: blur(20px) !important;
    z-index: 1805;
    visibility: visible;
    .SearchContent {
        transform: scaleY(1);
        height: 40%;
        .an {
            transition-delay: 0.2s;
            opacity: 1;
        }
    }
}
.delay {
    transition-delay: 0.2s !important;
}
</style>
