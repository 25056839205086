<template>
    <div style="line-height: normal">
        <ul>
            <template v-for="(item, index) in waterfallArr">
                <li ref="waterfallItem" :key="index" v-if="item.status">
                    <div style="margin-bottom: 10px; position: relative; display: inline-block; width: 100%; text-align: left">
                        <div>
                            <div class="testBox" @click="clickCategory('', item.id)">
                                <p style="font-size: 16px; font-weight: 600; display: inline-block">
                                    {{ item.category_title }}
                                </p>
                                <div class="testI">
                                    <img src="https://iv.okvu.cn/vugw/img/vu818.png" />
                                </div>
                            </div>
                            <hr style="opacity: 0.3; margin: 10px 0" />
                            <template v-for="itemT in item.children">
                                <p
                                    v-if="itemT.status"
                                    class="littleTitle"
                                    :key="itemT.id"
                                    @click="clickCategory(itemT.id, item.id)"
                                    :class="{ avtive: activeId == itemT.id }"
                                >
                                    {{ itemT.category_title }}
                                </p>
                            </template>
                        </div>
                    </div>
                </li>
            </template>
        </ul>
    </div>
</template>
<script>
import axios from "axios";

export default {
    data() {
        return {
            waterfallArr: [],
            activeId: "",
        };
    },
    async created() {
        let { data } = await axios.get("/api/project/category_client");
        this.waterfallArr = data;
    },
    methods: {
        clickCategory(id, fuid = "") {
            // 客户案例下拉列表项点击事件
            this.activeId = id;
            this.$emit("isShow");
            // this.$emit('clickBtn', data);
            this.$router.push({
                path: "/case",
                query: {
                    id,
                    fuid,
                },
            });
        },
    },
    watch: {
        $route() {
            this.category_title = "";
        },
    },
};
</script>
<style scoped lang="less">
.avtive {
    color: #7982f1;
    font-size: 18px !important;
    margin-top: 10px !important;
}
.testBox {
    color: #7982f1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
        .testI {
            display: block;
        }
    }
    .testI {
        display: none;
        width: 16px;
        height: 16px;
        overflow: hidden;
        position: relative;
        img {
            position: absolute;
            top: -81px;
            left: -1131px;
            width: 1200px;
        }
    }
}
ul {
    column-count: 4;
    // height: 100vh;
}
.littleTitle {
    margin-top: 15px;
    font-size: 14px;
    cursor: pointer;
}
.littleTitle:hover {
    font-size: 18px !important;
    margin-top: 10px !important;
    color: #7982f1;
}
.littleTitle:focus {
    font-size: 18px !important;
    color: #7982f1;
}
li {
    height: auto;
    position: relative;
    font-size: 14px;
    box-sizing: border-box;
    margin: 0 0 10px 0;
}
</style>
