<template>
    <!-- 顶部解决方案列表 -->
    <ul class="ul">
        <li
            v-for="el in list"
            :key="el.id"
            @click="
                $emit('hidden');
                $router.push('/SolutionDetails?id=' + el.id);
            "
        >
            <div>
                <img :src="el.icon" />
            </div>
            <div>
                <div>{{ el.title }}</div>
                <div>
                    <span>{{ el.intro }}&nbsp;</span>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            list: [],
        };
    },
    async created() {
        let { data } = await axios.get('/api/solution/allthree');
        this.list = data;
    },
};
</script>

<style lang="less" scoped>
.ul {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    justify-content: flex-start;
    align-content: flex-start;
    & > li {
        width: 45%;
        height: 14%;
        // margin-top: 5px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        & > div:nth-of-type(1) {
            width: 20%;
            height: 50px;
            margin-right: 15px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        & > div:nth-of-type(2) {
            font-size: 14px;
            line-height: 25px;
            width: 65%;
            div {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            span {
                font-size: 12px;
                color: grey;
            }
        }
        &:hover {
            box-shadow: 1px 1px 10px 1px gainsboro;
            border-radius: 10px;
        }
    }
}
</style>
