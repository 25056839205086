import Vue from "vue";
import VueRouter from "vue-router";
import getName from "./getName";
import getDevice from "./getDevice";

import axios from "axios";
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
    // { path: "/", redirect: "/home" },
    {
        path: "/",
        name: "home",
        // component: resolve => require(["../views/Home.vue"], resolve),
        component: () => import("../views/Home.vue"),
    },
    {
        path: "/info/:id",
        name: "info",
        component: () => import("@/components/common/WebBottom/info.vue"),
    },
    {
        path: "/about",
        name: "about",
        component: resolve => require(["../views/About.vue"], resolve),
    },
    {
        path: "/tsMore",
        name: "tsMore",
        component: resolve => require(["../components/content/TheService/TsMore/TsMore.vue"], resolve),
    },
    {
        path: "/tsSecurity",
        name: "tsSecurity",
        component: resolve => require(["../components/content/TheService/TsSecurity/TsSecurity.vue"], resolve),
    },
    {
        path: "/partner",
        name: "partner",
        component: resolve => require(["../views/Partner.vue"], resolve),
    },
    {
        path: "/partner2",
        name: "partner2",
        component: resolve => require(["../views/Partner2.vue"], resolve),
    },
    {
        path: "/partner3",
        name: "partner3",
        component: resolve => require(["../views/Partner3.vue"], resolve),
    },
    {
        path: "/partner3Details",
        name: "partner3Details",
        component: resolve => require(["../components/content/Partner/partnerDetails.vue"], resolve),
    },
    {
        path: "/partner2Details",
        name: "partner2Details",
        component: resolve => require(["../components/content/Partner/partnerDetails.vue"], resolve),
    },
    {
        path: "/the404",
        name: "the404",
        component: resolve => require(["../views/The404.vue"], resolve),
    },
    {
        path: "/theNot",
        name: "theNot",
        component: resolve => require(["../views/TheNot.vue"], resolve),
    },
    {
        path: "/searchPage",
        name: "searchPage",
        component: resolve => require(["../views/SearchPage.vue"], resolve),
    },
    {
        path: "/business",
        name: "business",
        component: resolve => require(["../views/Business.vue"], resolve),
    },
    {
        path: "/theSystem",
        name: "theSystem",
        component: resolve => require(["../views/TheSystem.vue"], resolve),
    },
    {
        path: "/theService",
        name: "theService",
        component: resolve => require(["../views/TheService.vue"], resolve),
    },
    // 客户案例
    {
        path: "/case",
        name: "case",
        component: resolve => require(["../views/Case.vue"], resolve),
        children: [
            {
                path: "listDetail/:id",
                name: "listDetail",
                component: resolve => require(["../components/content/Case/CaseList/listDetail"], resolve),
            },
        ],
    },
    {
        path: "/movement",
        name: "movement",
        component: resolve => require(["../views/Movement.vue"], resolve),
    },
    {
        path: "/movementDetail/:id",
        name: "movementDetail",
        component: resolve => require(["../components/content/Movement/movementDetail"], resolve),
    },
    {
        path: "/contact",
        name: "contact",
        // component: resolve => require(["../views/Contact.vue"], resolve),
        component: () => import("@/views/Contact.vue"),
    },
    {
        path: "/award",
        name: "award",
        component: resolve => require(["../views/Award.vue"], resolve),
    },
    {
        path: "/vyCloud",
        name: "vyCloud",
        component: resolve => require(["../views/VyCloud.vue"], resolve),
    },
    {
        path: "/domain",
        name: "domain",
        component: resolve => require(["../views/Domain.vue"], resolve),
    },
    // -----------------了解威有--------------------
    {
        path: "/honor",
        name: "honor",
        component: resolve => require(["../components/content/Abouts/Honor/Honor.vue"], resolve),
    },
    {
        path: "/recruit",
        name: "recruit",
        component: resolve => require(["../components/content/Abouts/Recruit/Recruit.vue"], resolve),
    },
    {
        path: "/adevelop",
        name: "adevelop",
        component: resolve => require(["../components/content/Abouts/Adevelopment/Adevelopment.vue"], resolve),
    },
    {
        path: "/staffPage",
        name: "staffPage",
        component: resolve => require(["../components/content/Abouts/StaffPage/StaffPage.vue"], resolve),
    },
    {
        path: "/spDetail",
        name: "spDetail",
        component: resolve => require(["../components/content/Abouts/StaffPage/SpDetail/SpDetail.vue"], resolve),
    },
    // --------------------人才招聘相关-----------------
    {
        path: "/region",
        name: "region",
        component: resolve => require(["../components/content/Abouts/Recruit/RegionPage.vue"], resolve),
    },
    {
        path: "/invitPage",
        name: "invitPage",
        component: resolve => require(["../components/content/Abouts/Recruit/InvitPage.vue"], resolve),
    },
    // -------------------软件介绍-----------------
    {
        path: "/thePhone",
        name: "thePhone",
        component: resolve => require(["../components/content/TheSoftware/ThePhone/ThePhone.vue"], resolve),
    },
    {
        path: "/thePc",
        name: "thePc",
        component: resolve => require(["../components/content/TheSoftware/ThePC/ThePC.vue"], resolve),
    },
    {
        path: "/theApp",
        name: "theApp",
        component: resolve => require(["../components/content/TheSoftware/TheApp/TheApp.vue"], resolve),
    },
    {
        path: "/theErp",
        name: "theErp",
        component: resolve => require(["../components/content/TheSoftware/Software/Wlh.vue"], resolve),
    },
    {
        path: "/theVR",
        name: "theVR",
        component: resolve => require(["../components/content/TheSoftware/theVR/theVR_index.vue"], resolve),
    },
    {
        path: "/theCRM",
        name: "theCRM",
        component: resolve => require(["../components/content/TheSoftware/TheCRM/TheCRM.vue"], resolve),
    },
    {
        path: "/theApplet",
        name: "theApplet",
        component: resolve => require(["../components/content/TheSoftware/TheApplet/TheApplet.vue"], resolve),
    },
    {
        path: "/marketing",
        name: "marketing",
        component: resolve => require(["../components/content/TheSoftware/Marketing/Marketing.vue"], resolve),
    },
    {
        path: "/software",
        name: "software",
        component: resolve => require(["../components/content/TheSoftware/Software/Wlh.vue"], resolve),
    },
    // ------------------解决方案-------------------
    {
        path: "/Solution",
        name: "Solution",
        component: resolve => require(["../components/content/Mpromotion/index.vue"], resolve),
    },
    {
        path: "/SolutionDetails",
        name: "SolutionDetails",
        component: resolve => require(["../components/content/Mpromotion/SolutionDetails.vue"], resolve),
    },
    {
        path: "/searchEg",
        name: "searchEg",
        component: resolve => require(["../components/content/Mpromotion/SearchEg/SearchEg.vue"], resolve),
    },
    {
        path: "/wholeNm",
        name: "wholeNm",
        component: resolve => require(["../components/content/Mpromotion/WholeNm/WholeNm.vue"], resolve),
    },
    {
        path: "/exhibition",
        name: "exhibition",
        component: resolve => require(["../components/content/Mpromotion/Exhibition/Exhibition.vue"], resolve),
    },
    {
        path: "/flowC",
        name: "flowC",
        component: resolve => require(["../components/content/Mpromotion/FlowC/FlowC.vue"], resolve),
    },
    // ------------------威有动静详情页-------------------
    // -------------------首页热门服务详情页------------------
    {
        path: "/hsContent",
        name: "hsContent",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent/HsContent.vue"], resolve),
    },
    {
        path: "/hsContent12",
        name: "hsContent12",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent/HsContent12.vue"], resolve),
    },
    {
        path: "/hsContent13",
        name: "hsContent13",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent/HsContent13.vue"], resolve),
    },
    {
        path: "/hsContent14",
        name: "hsContent14",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent/HsContent14.vue"], resolve),
    },
    {
        path: "/hsContent15",
        name: "hsContent15",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent/HsContent15.vue"], resolve),
    },
    {
        path: "/hsContent17",
        name: "hsContent17",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent/HsContent17.vue"], resolve),
    },
    {
        path: "/hsContent18",
        name: "hsContent18",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent/HsContent18.vue"], resolve),
    },
    {
        path: "/hsContent2",
        name: "hsContent2",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent2/HsContent2.vue"], resolve),
    },
    {
        path: "/hsContent21",
        name: "hsContent21",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent2/HsContent21.vue"], resolve),
    },
    {
        path: "/hsContent22",
        name: "hsContent22",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent2/HsContent22.vue"], resolve),
    },
    {
        path: "/hsContent3",
        name: "hsContent3",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent3/HsContent3.vue"], resolve),
    },
    {
        path: "/hsContent31",
        name: "hsContent31",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent3/HsContent31.vue"], resolve),
    },
    {
        path: "/hsContent41",
        name: "hsContent41",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent3/HsContent41.vue"], resolve),
    },
    {
        path: "/hsContent42",
        name: "hsContent42",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent3/HsContent42.vue"], resolve),
    },
    {
        path: "/hsContent43",
        name: "hsContent43",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent3/HsContent43.vue"], resolve),
    },
    {
        path: "/hsContent44",
        name: "hsContent44",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent3/HsContent44.vue"], resolve),
    },
    {
        path: "/hsContent45",
        name: "hsContent45",
        component: resolve => require(["../components/content/Homes/HotServices/HsContent3/HsContent45.vue"], resolve),
    },
    // ------------------首页服务市场详情页-------------------
    {
        path: "/proContent",
        name: "proContent",
        component: resolve => require(["../components/content/Homes/Product/ProContent/ProContent.vue"], resolve),
    },
    {
        path: "/proContent3",
        name: "proContent3",
        component: resolve => require(["../components/content/Homes/Product/ProContent3/ProContent3.vue"], resolve),
    },
    {
        path: "/proContent4",
        name: "proContent4",
        component: resolve => require(["../components/content/Homes/Product/ProContent4/ProContent4.vue"], resolve),
    },
    {
        path: "/proContent5",
        name: "proContent5",
        component: () => import("../components/content/Homes/Product/ProContent5/ProContent5.vue"),
    },
    {
        path: "/proContent6",
        name: "proContent6",
        component: resolve => require(["../components/content/Homes/Product/ProContent6/ProContent6.vue"], resolve),
    },
    // -----------------威有云市场--------------------
    {
        path: "/dmDetail",
        name: "dmDetail",
        component: resolve => require(["../components/content/Domains/DmDetail/DmDetail.vue"], resolve),
    },
    {
        path: "/dmSale",
        name: "dmSale",
        component: resolve => require(["../components/content/Domains/DmSale/DmSale.vue"], resolve),
    },
    {
        path: "/tbusinDetail",
        name: "tbusinDetail",
        component: resolve => require(["../components/content/Thebusiness/TbusinDetail.vue"], resolve),
    },
    {
        path: "/trademark",
        name: "trademark",
        component: resolve => require(["../components/content/Thebusiness/Trademark.vue"], resolve),
    },
    {
        path: "/tmSale",
        name: "tmSale",
        component: resolve => require(["../components/content/Thebusiness/TmSale.vue"], resolve),
    },
    {
        path: "/theSsl",
        name: "theSsl",
        component: resolve => require(["../components/content/Thebusiness/TheSsl.vue"], resolve),
    },
    {
        path: "/dmRecord",
        name: "dmRecord",
        component: resolve => require(["../components/content/Domains/DmRecord.vue"], resolve),
    },
    {
        path: "/dmLists",
        name: "dmLists",
        component: resolve => require(["../components/content/Domains/DmLists.vue"], resolve),
    },
    {
        path: "/vyListp",
        name: "vyListp",
        component: resolve => require(["../components/content/VyClouds/VyListp/VyListp.vue"], resolve),
    },
    {
        path: "/vyDetail",
        name: "vyDetail",
        component: resolve => require(["../components/content/VyClouds/VyDetail/VyDetail.vue"], resolve),
    },
    // ---------定价-------------------------------------------Details
    {
        path: "/fixPriceDetails",
        name: "fixPriceDetails",
        component: resolve => require(["../views/fixPriceDetails.vue"], resolve),
    },
    {
        path: "/fixPrice",
        name: "fixPrice",
        component: resolve => require(["../views/fixPrice.vue"], resolve),
    },
    // 网站关闭状态
    {
        path: "/close",
        name: "close",
        component: resolve => require(["../views/close.vue"], resolve),
    },
    {
        path: "*",
        redirect: "/",
    },
];

const router = new VueRouter({
    // mode: "hash",
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
/**
 * 添加用户行为
 */
function addUserBehavior(to, from) {
    if (to.path == "/close" || from.path == "/") return;
    const type = getDevice();
    axios.get("/api/website/visit", {
        params: {
            page: `${getName(from.name)}=>${getName(to.name)}`,
            type,
        },
    });
}
router.beforeEach(function (to, from, next) {
    addUserBehavior(to, from);
    if (to.path == "/close" && Vue.prototype.webStatus != "close") {
        next("/home");
        return;
    }
    if (Vue.prototype.webStatus == "close" && to.path != "/close") {
        next("/close");
        return;
    }
    next();
});

export default router;
