const arr = [
    {
        routeName: "home",
        name: "首页",
    },
    {
        routeName: "hsContent",
        name: "首页官网",
    },
    {
        routeName: "hsContent12",
        name: "首页品牌",
    },
    {
        routeName: "hsContent13",
        name: "首页外贸",
    },
    {
        routeName: "hsContent14",
        name: "首页营销",
    },
    {
        routeName: "hsContent15",
        name: "首页行业门户",
    },
    {
        routeName: "hsContent44",
        name: "首页数字化转型",
    },
    {
        routeName: "theApp",
        name: "软件介绍应用",
    },
    {
        routeName: "hsContent18",
        name: "首页智能硬件",
    },

    {
        routeName: "hsContent17",
        name: "首页热门服务手机网站",
    },
    {
        routeName: "hsContent21",
        name: "首页其他开发",
    },
    {
        routeName: "hsContent22",
        name: "首页小程序",
    },
    {
        routeName: "hsContent31",
        name: "首页电商",
    },
    {
        routeName: "hsContent2",
        name: "首页微商城",
    },
    {
        routeName: "hsContent45",
        name: "首页短视频",
    },
    {
        routeName: "hsContent3",
        name: "首页代运营",
    },
    {
        routeName: "hsContent43",
        name: "首页广告屏",
    },
    {
        routeName: "hsContent41",
        name: "首页SEM",
    },
    {
        routeName: "hsContent42",
        name: "首页朋友圈",
    },
    {
        routeName: "proContent",
        name: "首页品牌网站设计",
    },
    {
        routeName: "proContent3",
        name: "首页微信广告",
    },
    {
        routeName: "proContent4",
        name: "首页一物一码",
    },
    {
        routeName: "proContent5",
        name: "首页集团上市公司网站制作",
    },
    {
        routeName: "proContent6",
        name: "首页威有云服务",
    },
    {
        routeName: "searchEg",
        name: "首页SEO全网推",
    },
    {
        routeName: "thePhone",
        name: "软件介绍手机版",
    },
    {
        routeName: "honor",
        name: "了解威有荣誉资质",
    },
    {
        routeName: "adevelop",
        name: "了解威有发展历程",
    },
    {
        routeName: "recruit",
        name: "了解威有人才招聘",
    },
    {
        routeName: "region",
        name: "人才招聘岗位详情页",
    },
    {
        routeName: "invitPage",
        name: "人才招聘推荐好友",
    },
    {
        routeName: "staffPage",
        name: "了解威有优秀员工",
    },
    {
        routeName: "spDetail",
        name: "优秀员工详情页",
    },
    {
        routeName: "thePc",
        name: "软件介绍电脑版",
    },
    {
        routeName: "theApplet",
        name: "软件介绍小程序",
    },
    {
        routeName: "marketing",
        name: "软件介绍全网营销",
    },
    {
        routeName: "theErp",
        name: "软件介绍ERP",
    },
    {
        routeName: "theVR",
        name: "软件介绍VR",
    },
    {
        routeName: "theCRM",
        name: "软件介绍CRM",
    },
    {
        routeName: "tsMore",
        name: "服务保障搬迁",
    },
    {
        routeName: "tsSecurity",
        name: "服务保障安全",
    },
    {
        routeName: "case",
        name: "客户案例",
    },
    {
        routeName: "listDetail",
        name: "客户案例详情",
    },
    {
        routeName: "Solution",
        name: "解决方案",
    },
    {
        routeName: "SolutionDetails",
        name: "解决方案详情页",
    },
    {
        routeName: "movement",
        name: "威有动静分类页",
    },
    {
        routeName: "movementDetail",
        name: "威有动静详情页",
    },
    {
        routeName: "award",
        name: "推荐有奖",
    },
    {
        routeName: "contact",
        name: "联系威有",
    },
    {
        routeName: "searchPage",
        name: "搜索页",
    },
    {
        routeName: "partner",
        name: "申请合作",
    },
    {
        routeName: "partner2",
        name: "合作伙伴",
    },
    {
        routeName: "partner2Details",
        name: "合作伙伴详情页",
    },
    {
        routeName: "partner3",
        name: "分享机遇",
    },
    {
        routeName: "partner3Details",
        name: "分享机遇详情页",
    },
    {
        routeName: "the404",
        name: "404页",
    },
    {
        routeName: "fixPrice",
        name: "定价页",
    },
    {
        routeName: "fixPriceDetails",
        name: "定价详情页",
    },
];
/**
 *
 * @param {String} routeName
 * @returns 页面的描述等
 */
export default function (routeName) {
    let obj = arr.find(e => e.routeName == routeName);
    return obj ? obj.name : "未匹配到指定页面";
}
