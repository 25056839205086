<template>
    <div class="topTabsBox">
        <div class="ttBox">
            <!-- 导航 -->
            <div
                class="topNavigation"
                :class="{ tnBg: theBg, alsoWhite: alsoWhite }"
                :style="theAnim ? 'background-color: #fff' : ''"
            >
                <div class="navigationBox" :class="theTx ? 'tnText' : ''">
                    <!-- 图标 -->
                    <div class="weiyouIcon" @click="goHome" style="cursor: pointer">
                        <img :src="getWebData().logo" class="iconImg" />
                    </div>
                    <!-- 导航列表 -->
                    <div class="navigationList">
                        <!-- @mousewheel.prevent 禁止滚轮滚动 -->
                        <ul class="nglUl" v-if="tableData.length">
                            <li class="nlLi" :class="{ nlActive: isActive === 1 }" @click.stop="goThePage(1)">
                                <div class="nllP">
                                    首页
                                    <div class="nlB">
                                        <img v-show="isActive === 1" src="http://iv.okvu.cn/vugw/img/vu818.png" class="nlbImg" />
                                    </div>
                                </div>
                            </li>
                            <!-- 了解威有 -->
                            <li class="nlLi" :class="{ nlActive: isActive === 2 }" @mousewheel.prevent>
                                <div class="nllP">
                                    了解威有
                                    <div class="nlB">
                                        <img v-show="isActive === 2" src="http://iv.okvu.cn/vugw/img/vu818.png" class="nlbImg" />
                                    </div>
                                </div>

                                <!-- 下拉导航内容 -->
                                <div class="navigationContent">
                                    <!-- 首页内容 -->
                                    <!-- :style="{'background':`url(${backgroundImages[index]})`}" -->
                                    <div class="ncHome">
                                        <div class="nhLeft">
                                            <p class="nhlP">{{ tableData[0].title }}</p>
                                            <div class="nhlT1">
                                                {{ tableData[0].intro }}
                                            </div>
                                            <div class="nhlT2">
                                                <a class="nt2A" :href="tableData[0].project_url">{{
                                                    tableData[0].project_title
                                                }}</a>
                                            </div>
                                        </div>
                                        <div class="nhContent3" style="width: 57%">
                                            <ul class="zou_ul">
                                                <li
                                                    class="nc3Li"
                                                    @click.stop.prevent="goTabData2(1)"
                                                    :class="{ nc3Active: isNc3 === 1 }"
                                                >
                                                    <div class="right">
                                                        <p>
                                                            <img :src="'./img/pro_icon.png'" />
                                                            <span>荣誉资质</span>
                                                        </p>
                                                        <p>高性能私域营销电商系统</p>
                                                    </div>
                                                </li>
                                                <li
                                                    class="nc3Li"
                                                    @click.stop.prevent="goTabData2(2)"
                                                    :class="{ nc3Active: isNc3 === 2 }"
                                                >
                                                    <div class="left">
                                                        <p>
                                                            <img :src="'./img/pro_icon.png'" />
                                                            <span>发展历程</span>
                                                        </p>
                                                        <p>高性能私域营销电商系统</p>
                                                    </div>
                                                </li>
                                                <li
                                                    class="nc3Li"
                                                    @click.stop.prevent="goTabData2(3)"
                                                    :class="{ nc3Active: isNc3 === 3 }"
                                                >
                                                    <div class="right">
                                                        <p>
                                                            <img :src="'./img/pro_icon.png'" />
                                                            <span>人才招聘</span>
                                                        </p>
                                                        <p>高性能私域营销电商系统</p>
                                                    </div>
                                                </li>
                                                <li
                                                    class="nc3Li"
                                                    @click.stop.prevent="goTabData2(4)"
                                                    :class="{ nc3Active: isNc3 === 4 }"
                                                >
                                                    <div class="left">
                                                        <p>
                                                            <img :src="'./img/pro_icon.png'" />
                                                            <span>卓越之星</span>
                                                        </p>
                                                        <p>高性能私域营销电商系统</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="nhRight buding">
                                            <h3><span>全国统一客服热线</span></h3>
                                            <h1 v-if="getWebData().phone">
                                                {{
                                                    `${getWebData().phone.slice(0, 3)}-${getWebData().phone.slice(
                                                        3,
                                                        7
                                                    )}-${getWebData().phone.slice(7, 11)}`
                                                }}
                                            </h1>
                                            <p>(每天：9:00 — 18:00)</p>
                                            <div>
                                                <p><span>地址：</span>{{ getWebData().company_address }}</p>
                                                <p><span>邮箱：</span>{{ getWebData().email }}</p>
                                                <p><span>总机：</span>{{ getWebData().phone }}</p>
                                                <p><span>售后：</span>{{ getWebData().after_sales_phone }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- 软件介绍 -->
                            <li class="nlLi" :class="{ nlActive: isActive === 3 }" @mousewheel.prevent>
                                <!-- @click.stop="goThePage(3)" -->
                                <div class="nllP">
                                    软件介绍
                                    <div class="nlB">
                                        <img v-show="isActive === 3" src="http://iv.okvu.cn/vugw/img/vu818.png" class="nlbImg" />
                                    </div>
                                </div>

                                <!-- 下拉导航内容 -->
                                <div class="navigationContent">
                                    <!-- 首页内容 -->
                                    <div class="ncHome">
                                        <div class="nhLeft">
                                            <p class="nhlP">{{ tableData[1].title }}</p>
                                            <div class="nhlT1">
                                                {{ tableData[1].intro }}
                                            </div>
                                            <div class="nhlT2">
                                                <a class="nt2A" :href="tableData[1].project_url">{{
                                                    tableData[1].project_title
                                                }}</a>
                                            </div>
                                        </div>
                                        <div class="nhContent3">
                                            <ul class="zou_ul w25">
                                                <li
                                                    v-for="(el, index) in [
                                                        '手机版',
                                                        '电脑版',
                                                        '应用',
                                                        '小程序',
                                                        '全网营销',
                                                        'ERP',
                                                        'VR',
                                                        'CRM',
                                                    ]"
                                                    :key="el"
                                                    class="nc3Li"
                                                    @click.stop="goTabData3(index + 1)"
                                                    :class="{ nc3Active: isNc32 === index + 1 }"
                                                >
                                                    <div>
                                                        <p>
                                                            <img :src="'./img/pro_icon.png'" />
                                                            <span>{{ el }}</span>
                                                        </p>
                                                        <p>高性能私域营销电商</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="nhRight">
                                            <template v-if="navRightList.find(e => e.title == '软件介绍')">
                                                <div class="nhrTop">
                                                    <a
                                                        :href="navRightList.find(e => e.title == '软件介绍').top_url"
                                                        @click="
                                                            e => {
                                                                navRightList.find(e => e.title == '软件介绍').top_url
                                                                    ? ''
                                                                    : e.preventDefault();
                                                            }
                                                        "
                                                        target="_blank"
                                                    >
                                                        <p class="ntText">
                                                            {{ navRightList.find(e => e.title == "软件介绍").top_title }}
                                                        </p>
                                                        <div class="ntIcon">
                                                            <img :src="navRightList.find(e => e.title == '软件介绍').top_img" />
                                                        </div>
                                                    </a>
                                                    <ul>
                                                        <template
                                                            v-for="(el, i) in navRightList.find(e => e.title == '软件介绍')
                                                                .children"
                                                        >
                                                            <li class="nhrLi" :key="el.id">
                                                                <a :href="el.url" target="_blank">
                                                                    <p class="nhrNum">{{ i + 1 }}</p>
                                                                    <p class="nhrText">{{ el.title }}</p>
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </div>
                                                <div class="nhrBottom">
                                                    <a
                                                        :href="navRightList.find(e => e.title == '软件介绍').bottom_url"
                                                        @click="
                                                            e => {
                                                                navRightList.find(e => e.title == '软件介绍').bottom_url
                                                                    ? ''
                                                                    : e.preventDefault();
                                                            }
                                                        "
                                                        target="_blank"
                                                    >
                                                        <div class="nbTop">
                                                            <p class="nbTitle">威有</p>
                                                            <div class="nbIcon">
                                                                <img
                                                                    :src="
                                                                        navRightList.find(e => e.title == '软件介绍').bottom_img
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                        <p class="nbText">
                                                            {{ navRightList.find(e => e.title == "软件介绍").bottom_title }}
                                                        </p>
                                                    </a>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- 服务保障 -->
                            <li class="nlLi" :class="{ nlActive: isActive === 4 }" @mousewheel.prevent>
                                <div class="nllP">
                                    服务保障
                                    <div class="nlB">
                                        <img v-show="isActive === 4" src="http://iv.okvu.cn/vugw/img/vu818.png" class="nlbImg" />
                                    </div>
                                </div>

                                <!-- 下拉导航内容 -->
                                <div class="navigationContent">
                                    <!-- 首页内容 -->
                                    <div class="ncHome">
                                        <div class="nhLeft">
                                            <p class="nhlP">{{ tableData[2].title }}</p>
                                            <div class="nhlT1">
                                                {{ tableData[2].intro }}
                                            </div>
                                            <div class="nhlT2">
                                                <a class="nt2A" :href="tableData[2].project_url">{{
                                                    tableData[2].project_title
                                                }}</a>
                                            </div>
                                        </div>
                                        <div class="nhContent3">
                                            <ul class="nc3Ul zou_ul">
                                                <li
                                                    class="nc3Li"
                                                    @click.stop="goTabData5(1)"
                                                    :class="{ nc3Active: isNc33 === 1 }"
                                                >
                                                    <div class="right">
                                                        <p>
                                                            <img :src="'./img/pro_icon.png'" />
                                                            <span>搬迁</span>
                                                        </p>
                                                        <p>高性能私域营销电商</p>
                                                    </div>
                                                </li>
                                                <li
                                                    class="nc3Li"
                                                    @click.stop="goTabData5(2)"
                                                    :class="{ nc3Active: isNc33 === 2 }"
                                                >
                                                    <div class="left">
                                                        <p>
                                                            <img :src="'./img/pro_icon.png'" />
                                                            <span>安全</span>
                                                        </p>
                                                        <p>高性能私域营销电商</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="nhRight">
                                            <template v-if="navRightList.find(e => e.title == '服务保障')">
                                                <div class="nhrTop">
                                                    <a
                                                        :href="navRightList.find(e => e.title == '服务保障').top_url"
                                                        @click="
                                                            e => {
                                                                navRightList.find(e => e.title == '服务保障').top_url
                                                                    ? ''
                                                                    : e.preventDefault();
                                                            }
                                                        "
                                                        target="_blank"
                                                    >
                                                        <p class="ntText">
                                                            {{ navRightList.find(e => e.title == "服务保障").top_title }}
                                                        </p>
                                                        <div class="ntIcon">
                                                            <img :src="navRightList.find(e => e.title == '服务保障').top_img" />
                                                        </div>
                                                    </a>
                                                    <ul>
                                                        <template
                                                            v-for="(el, i) in navRightList.find(e => e.title == '服务保障')
                                                                .children"
                                                        >
                                                            <li class="nhrLi" :key="el.id">
                                                                <a :href="el.url" target="_blank">
                                                                    <p class="nhrNum">{{ i + 1 }}</p>
                                                                    <p class="nhrText">{{ el.title }}</p>
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </div>
                                                <div class="nhrBottom">
                                                    <a
                                                        :href="navRightList.find(e => e.title == '服务保障').bottom_url"
                                                        @click="
                                                            e => {
                                                                navRightList.find(e => e.title == '服务保障').bottom_url
                                                                    ? ''
                                                                    : e.preventDefault();
                                                            }
                                                        "
                                                        target="_blank"
                                                    >
                                                        <div class="nbTop">
                                                            <p class="nbTitle">威有</p>
                                                            <div class="nbIcon">
                                                                <img
                                                                    :src="
                                                                        navRightList.find(e => e.title == '服务保障').bottom_img
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                        <p class="nbText">
                                                            {{ navRightList.find(e => e.title == "服务保障").bottom_title }}
                                                        </p>
                                                    </a>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- 客户案例 -->
                            <li class="nlLi" :class="{ nlActive: isActive === 5 }">
                                <div class="hd">
                                    <span v-show="getWebData().hot_status">有活动</span>
                                </div>
                                <div class="nllP" @click.stop="goThePage(5)">
                                    客户案例
                                    <div class="nlB">
                                        <img v-show="isActive === 5" src="http://iv.okvu.cn/vugw/img/vu818.png" class="nlbImg" />
                                    </div>
                                </div>
                                <!-- 下拉导航内容 -->
                                <div class="navigationContent">
                                    <!-- 首页内容 -->
                                    <div class="ncHome">
                                        <div class="nhLeft">
                                            <p class="nhlP">{{ tableData[3].title }}</p>
                                            <div class="nhlT1">
                                                {{ tableData[3].intro }}
                                            </div>
                                            <div class="nhlT2">
                                                <img
                                                    v-show="getWebData().hot_status"
                                                    src="https://iv.okvu.cn/vugw/img/vuvip.gif"
                                                />
                                                <a class="nt2A" :href="tableData[3].project_url">{{
                                                    tableData[3].project_title
                                                }}</a>
                                            </div>
                                        </div>
                                        <div class="nhContent4">
                                            <caseClass @isShow="isShow" ref="caseClass" @clickBtn="clickCategory" />
                                        </div>
                                        <div class="nhRight" style="text-align: left">
                                            <div class="nhlT1">威有云为您提供完善而全面的客户支持服务</div>
                                            <div class="nhlT2">
                                                <ul>
                                                    <li class="nt2Li">
                                                        <div class="n2lImg">
                                                            <img src="http://iv.okvu.cn/vugw/img/vucloud.png" />
                                                        </div>
                                                        <p class="n2lP">1V1服务</p>
                                                    </li>
                                                    <li class="nt2Li">
                                                        <div class="n2lImg2">
                                                            <img src="http://iv.okvu.cn/vugw/img/vucloud.png" />
                                                        </div>
                                                        <p class="n2lP">技术200+</p>
                                                    </li>
                                                    <li class="nt2Li">
                                                        <div class="n2lImg3">
                                                            <img src="http://iv.okvu.cn/vugw/img/vucloud.png" />
                                                        </div>
                                                        <p class="n2lP">7x24小时服务</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- 解决方案 -->
                            <li class="nlLi" :class="{ nlActive: isActive === 6 }">
                                <div class="nllP" @click.stop="goThePage(6)">
                                    解决方案
                                    <div class="nlB">
                                        <img v-show="isActive === 6" src="http://iv.okvu.cn/vugw/img/vu818.png" class="nlbImg" />
                                    </div>
                                </div>

                                <!-- 下拉导航内容 -->
                                <div class="navigationContent">
                                    <!-- 首页内容 -->
                                    <div class="ncHome">
                                        <div class="nhLeft">
                                            <p class="nhlP">{{ tableData[4].title }}</p>
                                            <div class="nhlT1">
                                                {{ tableData[4].intro }}
                                            </div>
                                            <div class="nhlT2">
                                                <a class="nt2A" :href="tableData[4].project_url">{{
                                                    tableData[4].project_title
                                                }}</a>
                                            </div>
                                        </div>
                                        <div class="nhContent">
                                            <div class="nhcLeft">
                                                <div class="nlBox">
                                                    <div class="nlbTop">解决方案</div>
                                                    <jjfa
                                                        @hidden="
                                                            () => {
                                                                ycc(4);
                                                                isActive = 6;
                                                            }
                                                        "
                                                    ></jjfa>
                                                </div>
                                            </div>
                                            <div class="nhcRight">
                                                <div class="nrBox">
                                                    <div class="nrbTop">营销推广</div>
                                                    <ul>
                                                        <li
                                                            v-for="el in marketingList"
                                                            :key="el.id"
                                                            class="nrbLi"
                                                            @click.stop="goTabData4(el.url)"
                                                        >
                                                            <img :src="el.icon" />
                                                            <span>{{ el.title }}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="nhRight">
                                            <template v-if="navRightList.find(e => e.title == '解决方案')">
                                                <div class="nhrTop">
                                                    <a
                                                        :href="navRightList.find(e => e.title == '解决方案').top_url"
                                                        @click="
                                                            e => {
                                                                navRightList.find(e => e.title == '解决方案').top_url
                                                                    ? ''
                                                                    : e.preventDefault();
                                                            }
                                                        "
                                                        target="_blank"
                                                    >
                                                        <p class="ntText">
                                                            {{ navRightList.find(e => e.title == "解决方案").top_title }}
                                                        </p>
                                                        <div class="ntIcon">
                                                            <img :src="navRightList.find(e => e.title == '解决方案').top_img" />
                                                        </div>
                                                    </a>
                                                    <ul>
                                                        <template
                                                            v-for="(el, i) in navRightList.find(e => e.title == '解决方案')
                                                                .children"
                                                        >
                                                            <li class="nhrLi" :key="el.id">
                                                                <a :href="el.url" target="_blank">
                                                                    <p class="nhrNum">{{ i + 1 }}</p>
                                                                    <p class="nhrText">{{ el.title }}</p>
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </div>
                                                <div class="nhrBottom">
                                                    <a
                                                        :href="navRightList.find(e => e.title == '解决方案').bottom_url"
                                                        @click="
                                                            e => {
                                                                navRightList.find(e => e.title == '解决方案').bottom_url
                                                                    ? ''
                                                                    : e.preventDefault();
                                                            }
                                                        "
                                                        target="_blank"
                                                    >
                                                        <div class="nbTop">
                                                            <p class="nbTitle">威有</p>
                                                            <div class="nbIcon">
                                                                <img
                                                                    :src="
                                                                        navRightList.find(e => e.title == '解决方案').bottom_img
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                        <p class="nbText">
                                                            {{ navRightList.find(e => e.title == "解决方案").bottom_title }}
                                                        </p>
                                                    </a>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- 威有动静 -->
                            <li class="nlLi" :class="{ nlActive: isActive === 7 }" @mousewheel.prevent>
                                <div class="nllP">
                                    威有动静
                                    <div class="nlB">
                                        <img v-show="isActive === 7" src="http://iv.okvu.cn/vugw/img/vu818.png" class="nlbImg" />
                                    </div>
                                </div>

                                <!-- 下拉导航内容 -->
                                <div class="navigationContent">
                                    <!-- 首页内容 -->
                                    <div class="ncHome">
                                        <div class="nhLeft">
                                            <p class="nhlP">{{ tableData[5].title }}</p>
                                            <div class="nhlT1">
                                                {{ tableData[5].intro }}
                                            </div>
                                            <div class="nhlT2">
                                                <a class="nt2A" :href="tableData[5].project_url">{{
                                                    tableData[5].project_title
                                                }}</a>
                                            </div>
                                        </div>
                                        <div class="nhContent3">
                                            <ul class="nc3Ul zou_ul" style="justify-content: left !important">
                                                <li
                                                    v-for="(el, i) in wydjList"
                                                    :key="el.id"
                                                    class="nc3Li"
                                                    @click.stop="
                                                        ycc(5, '/movement?id=' + el.id);
                                                        isActive = 7;
                                                    "
                                                    v-show="el.status"
                                                >
                                                    <div :class="i % 2 == 1 ? 'left' : 'right'">
                                                        <p>
                                                            <img :src="'./img/pro_icon.png'" />
                                                            <span>{{ el.name }}</span>
                                                        </p>
                                                        <p>{{ el.describe }}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="nhRight">
                                            <template v-if="navRightList.find(e => e.title == '威有动静')">
                                                <div class="nhrTop">
                                                    <a
                                                        :href="navRightList.find(e => e.title == '威有动静').top_url"
                                                        @click="
                                                            e => {
                                                                navRightList.find(e => e.title == '威有动静').top_url
                                                                    ? ''
                                                                    : e.preventDefault();
                                                            }
                                                        "
                                                        target="_blank"
                                                    >
                                                        <p class="ntText">
                                                            {{ navRightList.find(e => e.title == "威有动静").top_title }}
                                                        </p>
                                                        <div class="ntIcon">
                                                            <img :src="navRightList.find(e => e.title == '威有动静').top_img" />
                                                        </div>
                                                    </a>
                                                    <ul>
                                                        <template
                                                            v-for="(el, i) in navRightList.find(e => e.title == '威有动静')
                                                                .children"
                                                        >
                                                            <li class="nhrLi" :key="el.id">
                                                                <a :href="el.url" target="_blank">
                                                                    <p class="nhrNum">{{ i + 1 }}</p>
                                                                    <p class="nhrText">{{ el.title }}</p>
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </div>
                                                <div class="nhrBottom">
                                                    <a
                                                        :href="navRightList.find(e => e.title == '威有动静').bottom_url"
                                                        @click="
                                                            e => {
                                                                navRightList.find(e => e.title == '威有动静').bottom_url
                                                                    ? ''
                                                                    : e.preventDefault();
                                                            }
                                                        "
                                                        target="_blank"
                                                    >
                                                        <div class="nbTop">
                                                            <p class="nbTitle">威有</p>
                                                            <div class="nbIcon">
                                                                <img
                                                                    :src="
                                                                        navRightList.find(e => e.title == '威有动静').bottom_img
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                        <p class="nbText">
                                                            {{ navRightList.find(e => e.title == "威有动静").bottom_title }}
                                                        </p>
                                                    </a>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- 威有云市场 -->
                            <li class="nlLi" :class="{ nlActive: isActive === 8 }" @mousewheel.prevent>
                                <div class="nllP" @click.stop="goThePage(8)">
                                    威有云
                                    <div class="nlB">
                                        <img v-show="isActive === 8" src="http://iv.okvu.cn/vugw/img/vu818.png" class="nlbImg" />
                                    </div>
                                </div>

                                <!-- 下拉导航内容 -->
                                <div class="navigationContent">
                                    <!-- 首页内容 -->
                                    <div class="ncHome">
                                        <div class="nhLeft">
                                            <p class="nhlP">{{ tableData[6].title }}</p>
                                            <div class="nhlT1">
                                                {{ tableData[6].intro }}
                                            </div>
                                            <div class="nhlT2">
                                                <a class="nt2A" :href="tableData[6].project_url">{{
                                                    tableData[6].project_title
                                                }}</a>
                                            </div>
                                        </div>
                                        <vun />
                                    </div>
                                </div>
                            </li>
                            <!-- 推荐有奖 -->
                            <li class="nlLi" :class="{ nlActive: isActive === 9 }" @click.stop="goThePage(9)">
                                <div class="nllP tjyj">
                                    <img src="https://iv.okvu.cn/vugw/img/tjyj.png" />
                                    <div class="nlB">
                                        <img v-show="isActive === 9" src="http://iv.okvu.cn/vugw/img/vu818.png" class="nlbImg" />
                                    </div>
                                </div>
                            </li>
                            <!-- 联系威有 -->
                            <li class="nlLi" :class="{ nlActive: isActive === 10 }" @click.stop="goThePage(10)">
                                <div class="nllP">
                                    联系威有
                                    <div class="nlB">
                                        <img v-show="isActive === 10" src="http://iv.okvu.cn/vugw/img/vu818.png" class="nlbImg" />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- 搜索图标 85deg-->
                    <div class="tnSerach">
                        <div class="tsIcon" @click="clickSerachIcon" :class="theAnim ? 'openAnim' : 'closeAnim'">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 下拉出的搜索框 -->
            <top-search
                ref="top_search"
                @theAnim="
                    s => {
                        theAnim = s;
                    }
                "
            ></top-search>
        </div>
    </div>
</template>

<script>
import bus from "../eventBus.js";
import axios from "axios";
import jjfa from "./components/jjfa.vue";
import TopSearch from "./components/TopSearch.vue";
import vun from "./components/vun.vue";
import caseClass from "./masony.vue";
export default {
    name: "TopTabs",
    components: {
        caseClass,
        jjfa,
        TopSearch,
        vun,
    },
    inject: ["getWebData"],
    data() {
        return {
            // 图标动画
            theAnim: false,
            // css样式
            isActive: 1,
            // 导航栏白色背景黑色字体
            theBg: false,
            theTx: true,
            // 点击搜索框隐藏滚动
            showSr: true,
            theSeaText: "",
            // 下拉菜单子元素点击
            isNc3: 0,
            isNc32: 0,
            isNc33: 0,
            isNc34: 0,
            // -----------
            alsoWhite: false,
            // 活动状态
            // activityStatus: true,
            // 简介相关
            tableData: [],
            wydjList: [],
            // 导航右侧
            navRightList: [],
            // 营销推广
            marketingList: [],
        };
    },
    mounted() {
        window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
        this.$bus.$on("alsoWhite", bool => {
            this.alsoWhite = bool;
        });
        // 获取简介的数据
        axios.get("/api/project/introList").then(res => {
            this.tableData = res.data;
        });
        // 获取右侧链接的数据
        axios.get("/api/hotspot/right").then(({ data }) => {
            this.navRightList = data;
        });
        // 获取营销推广的数据
        axios.get("/api/marketing/show").then(({ data }) => {
            this.marketingList = data;
        });
    },
    async created() {
        if (this.$route.name === "home") {
            this.theTx = true;
        } else {
            this.theTx = false;
        }
        // -------------------------
        let { data } = await axios.get("/api/article/categoryshow");
        this.wydjList = data.filter(e => e.status);
    },
    methods: {
        isShow() {
            let dom = document.getElementsByClassName("nlLi");
            let ngc = document.querySelectorAll(".navigationContent")[3];
            ngc.style.display = "none";
            setTimeout(() => {
                ngc.style.display = "";
            }, 100);
            Object.values(dom)[4].blur();
        },
        // 滚动隐藏
        scrollHandle(e) {
            let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
            this.theSeaText = "";
            if (top > 50) {
                this.theBg = true;
                this.theTx = false;
            } else {
                this.theBg = false;
                this.theTx = false;
            }
        },
        // 回到首页
        goHome() {
            this.theAnim = false;
            this.$router.push("/home");
            this.isActive = 1;
            this.theTx = true;
            this.isNc3 = 0;
            this.isNc32 = 0;
            this.isNc33 = 0;
            this.isNc34 = 0;
        },
        // 跳转页面
        goThePage(i) {
            this.theAnim = false;
            this.isActive = i;
            this.isNc3 = 0;
            this.isNc32 = 0;
            this.isNc33 = 0;
            this.isNc34 = 0;
            this.theTx = false;
            let ngc = document.querySelectorAll(".navigationContent")[6];
            setTimeout(() => {
                ngc.style.display = "";
            }, 100);
            switch (i) {
                case 1:
                    this.$router.push("/home");
                    this.theTx = true;
                    break;
                case 3:
                    this.$router.push("/theErp");
                    ngc.style.display = "none";
                    break;
                case 5:
                    this.$router.push("/case");
                    break;
                case 8:
                    console.log(this.$router);
                    // this.$router.to("okvu.com.cn");
                    window.location = "https://okvu.com.cn";
                    // this.$router.push("okvu.com.cn");
                    // ngc.style.display = "none";
                    break;
                case 9:
                    this.$router.push("/award");
                    break;
                case 10:
                    this.$router.push("/contact");
                    break;
                case 6:
                    this.$router.push("/Solution");
                    let ngc = document.querySelectorAll(".navigationContent")[4];
                    ngc.style.display = "none";
                    setTimeout(() => {
                        ngc.style.display = "";
                    }, 100);
                    break;
            }
        },
        clickCategory(data) {
            this.$router.push("/case");
            this.$nextTick(() => {
                bus.$emit("onCategory", data);
            });
        },
        // 了解威有
        goTabData2(i) {
            this.theAnim = false;
            let ngc = document.querySelectorAll(".navigationContent")[0];
            ngc.style.display = "none";
            this.isNc3 = i;
            this.isNc32 = 0;
            this.isNc33 = 0;
            this.isNc34 = 0;
            setTimeout(() => {
                ngc.style.display = "";
            }, 100);
            this.theTx = false;
            this.isActive = 2;
            switch (i) {
                case 1:
                    this.$router.push("/honor");
                    break;
                case 2:
                    this.$router.push("/adevelop");
                    break;
                case 3:
                    this.$router.push("/recruit");
                    break;
                case 4:
                    this.$router.push("/staffPage");
                    break;
            }
        },
        // 软件
        goTabData3(i) {
            this.theAnim = false;
            let ngc = document.querySelectorAll(".navigationContent")[1];
            ngc.style.display = "none";
            this.isNc3 = 0;
            this.isNc33 = 0;
            this.isNc34 = 0;
            this.isNc32 = i;
            setTimeout(() => {
                ngc.style.display = "";
            }, 100);
            this.isActive = 3;
            this.theTx = false;
            switch (i) {
                case 1:
                    this.$router.push("/thePhone");
                    break;
                case 2:
                    this.$router.push("/thePc");
                    break;
                case 3:
                    this.$router.push("/theApp");
                    break;
                case 4:
                    this.$router.push("/theApplet");
                    break;
                case 5:
                    this.$router.push("/marketing");
                    break;
                case 6:
                    this.$router.push("/theErp");
                    break;
                case 7:
                    this.$router.push("/theVR");
                    break;
                case 8:
                    this.$router.push("/theCRM");
                    break;
            }
        },
        // 解决方案
        goTabData4(url) {
            this.ycc(4);
            window.location.href = url;
        },
        // 服务保障
        goTabData5(i) {
            this.theAnim = false;
            this.isNc3 = 0;
            this.isNc33 = i;
            this.isNc34 = 0;
            this.isNc32 = 0;
            let ngc = document.querySelectorAll(".navigationContent")[2];
            ngc.style.display = "none";
            setTimeout(() => {
                ngc.style.display = "";
            }, 100);
            this.isActive = 4;
            this.theTx = false;
            switch (i) {
                case 1:
                    this.$router.push("/tsMore");
                    break;
                case 2:
                    this.$router.push("/tsSecurity");
                    break;
            }
        },
        // 威有动静
        goTabData7() {
            this.theAnim = false;
            this.isNc3 = 0;
            this.isNc33 = 0;
            this.isNc32 = 0;
            this.isActive = 7;
            this.theTx = false;
            this.$router.push("/movement");
            let ngc = document.querySelectorAll(".navigationContent")[5];
            ngc.style.display = "none";
            setTimeout(() => {
                ngc.style.display = "";
            }, 100);
        },
        // 用来隐藏和跳转
        ycc(num, path) {
            let ngc = document.querySelectorAll(".navigationContent")[num];
            ngc.style.display = "none";
            setTimeout(() => {
                ngc.style.display = "";
            }, 100);
            if (path) {
                this.$router.push(path);
            }
        },
        // 点击搜索图标
        clickSerachIcon() {
            let { status, show, hidden } = this.$refs.top_search;
            status ? hidden() : show();
            this.theAnim = !this.theAnim;
        },
    },
    watch: {
        theAnim(newVal) {
            let { show, hidden } = this.$refs.top_search;
            if (newVal) {
                show();
            } else {
                hidden();
            }
        },
        $route: {
            handler() {
                let arr = [
                    {
                        path: "",
                        isActive: 1,
                    },
                    {
                        path: "/honor",
                        isActive: 2,
                    },
                    {
                        path: "/adevelop",
                        isActive: 2,
                    },
                    {
                        path: "/recruit",
                        isActive: 2,
                    },
                    {
                        path: "/staffPage",
                        isActive: 2,
                    },
                    {
                        path: "/tsMore",
                        isActive: 4,
                    },
                    {
                        path: "/tsSecurity",
                        isActive: 4,
                    },
                    {
                        path: "/case",
                        isActive: 5,
                    },
                    {
                        path: "/Solution",
                        isActive: 6,
                    },
                    {
                        path: "/SolutionDetails",
                        isActive: 6,
                    },
                    {
                        path: "/vyCloud",
                        isActive: 8,
                    },
                    {
                        path: "/award",
                        isActive: 9,
                    },
                    {
                        path: "/contact",
                        isActive: 10,
                    },
                    {
                        path: "/movement",
                        isActive: 7,
                    },
                    {
                        path: "/movementDetail/:id",
                        isActive: 7,
                    },
                ];
                this.isActive = arr.find(e => e.path == this.$route.matched[0].path).isActive;
            },
            once: true,
        },
    },
};
</script>

<style lang="less" scoped>
@import url(./TopTabs.less);
</style>
