<template>
    <div id="app" v-cloak ref="app" v-loading.fullscreen="loading" element-loading-background="#fff">
        <!-- 顶部横幅和导航栏 -->
        <TopTabs></TopTabs>
        <keep-alive include="Case">
            <router-view :key="$route.name"></router-view>
        </keep-alive>
        <!-- 右侧悬浮 -->
        <RightBox></RightBox>
        <!-- 页面底部 -->
        <WebBottom v-if="WebBottomStatus"></WebBottom>
        <!-- 悬浮的热搜词 -->
        <hot-terms></hot-terms>
        <!-- 公告 -->
        <div :class="once ? 'app-notice' : ''" style="display: none">
            <div>
                <img src="https://iv.okvu.cn/vugw/img/vu818.png" />
            </div>
            <div>
                <img src="https://iv.okvu.cn/vugw/img/vu818.png" />
                <span>{{ webData.notice }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import TopTabs from "./components/common/TopTabs/TopTabs.vue";
import WebBottom from "./components/common/WebBottom/WebBottom.vue";
import RightBox from "./components/common/RightBox/RightBox.vue";
import axios from "axios";
import Cookies from "js-cookie";
import getDevice from "@/router/getDevice";
import HotTerms from "./components/content/HotTerms/HotTerms.vue";
export default {
    components: {
        TopTabs,
        RightBox,
        WebBottom,
        HotTerms,
    },
    name: "App",
    data() {
        return {
            once: false,
            // 加载状态
            loading: false,
            // 网站信息数据
            webData: {},
            // 所有广告图数据
            advData: [],
            WebBottomStatus: true,
        };
    },
    provide() {
        return {
            getWebData: this.getWebData,
            getAdvData: this.getAdvData,
            addUserBehaviorL(page) {
                // 添加用户行为
                axios.get("/api/website/visit", {
                    params: {
                        page,
                        type: getDevice(),
                    },
                });
            },
            changeWebBottom: s => {
                this.WebBottomStatus = s;
            },
        };
    },
    async created() {
        let _this = this;
        // 获取所有的广告图
        let { data: advData } = await axios.get("/api/advert/advertShow", { params: { type: 1 } });
        this.advData = advData;
        // setTimeout(() => {
        //     document.querySelectorAll("img").forEach(e => {
        //         e.addEventListener("dragdragstart", function (e) {
        //             e.preventDefault();
        //             e.dataTransfer.setData("text/plain", "This is a dummy data");
        //         });
        //     });
        // }, 2000);

        // document.body.ondrop = function (e) {
        //     e.preventDefault();
        //     e.stopPropagation();
        // };
    },
    async mounted() {
        const that = this;
        this.$bus.$on("openLoading", function () {
            // 打开加载状态遮罩
            that.loading = true;
            setTimeout(() => {
                that.loading = false;
            }, 1000);
        });

        // 自适应
        that.resetPage();
        window.addEventListener("resize", that.resetPage);

        let { data: webData } = await axios.get("/api/website/show");
        this.webData = webData;
        if (!Cookies.get(webData.cookie.name)) {
            Cookies.set(webData.cookie.name, webData.cookie.content, { expires: new Date("2100-01-01T00:00:00Z") });
        }
        this.setWeb(webData);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resetPage);
    },
    methods: {
        // 窗口变化缩放
        resetPage() {
            this.$nextTick(() => {
                var ww = document.documentElement.clientWidth;
                if (ww < 1440) {
                    var scale = ww / 1440;
                    document.body.style.zoom = scale;
                } else {
                    document.body.style.zoom = 1;
                }
            });
        },
        // 返回网站信息
        getWebData() {
            return this.webData;
        },
        // 根据传入id返回指定广告图
        getAdvData(id) {
            if (this.advData.length == 0) return {};
            return this.advData.find(e => e.id == id);
        },
        // 设置网站
        setWeb(webData) {
            let that = this;
            if (webData.drag_status == 0) closeDrag();
            if (webData.ctrls == 0) ctrls();
            if (webData.sorrow_status == 1) ad();
            if (webData.website_status == 0) close();
            if (webData.notice_status == 1) gg();
            if (webData.fonetwo == 0) {
                f12();
                contextmenu();
            }
            // 禁止拖拽
            function closeDrag() {
                window.addEventListener("dragstart", function (e) {
                    e.preventDefault();
                });
            }
            // 禁止F12
            function f12() {
                window.addEventListener("keydown", function (e) {
                    if (e.key === "F12") e.preventDefault();
                });
            }
            // 禁止右键菜单
            function contextmenu() {
                window.addEventListener("contextmenu", function (e) {
                    e.preventDefault();
                });
            }
            // 禁止ctrl+s
            function ctrls() {
                window.addEventListener("keydown", function (e) {
                    if (e.key === "s" && e.ctrlKey) e.preventDefault();
                });
            }
            // 开启哀悼状态
            function ad() {
                document.documentElement.classList.add("isAd");
            }
            // 网站状态变为 关闭
            function close() {
                Vue.prototype.webStatus = "close";
                that.$router.replace("/close");
            }
            // 开启滚动公告
            function gg() {
                that.once = true;
                // setTimeout(() => {
                //     that.once = false;
                //     // 45s
                // }, 45000);
            }
        },
    },
};
</script>

<style lang="less">
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    // 平滑滚动
    scroll-behavior: smooth;
    textarea {
        resize: none;
    }
}
body {
    overflow-x: hidden;
}
a-config-provider {
    height: max-content !important;
}
#app {
    text-align: center;
}
[v-cloak] {
    display: none;
}
/deep/ .BMap_cpyCtrl {
    display: none !important;
}
/deep/ .anchorBL {
    display: none !important;
}
// 富文本的p标签样式
.editor-p {
    padding: 0 20px;
    text-align: left;
    p {
        margin: 15px 0;
    }
}

// 哀悼状态滤镜
.isAd {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(1);
}
// 公告
.app-notice {
    position: fixed;
    left: 100vw;
    // left: 40vw;
    top: 50px;
    z-index: 1819;
    animation: appNoticeAn 15s linear infinite;
    display: flex !important;
    align-items: center;
    & > div {
        overflow: hidden;
        position: relative;
        img {
            position: absolute;
        }
    }
    & > div:nth-of-type(1) {
        width: 216px;
        height: 131px;
        img {
            top: -1335px;
            left: -912px;
        }
    }
    & > div:nth-of-type(2) {
        width: 700px;
        height: 105px;
        span {
            position: absolute;
            left: 2%;
            top: 50%;
            transform: translateY(-50%);
            color: white;
            font-size: 30px;
        }
        img {
            top: -2280px;
            left: -4px;
        }
    }
}
@keyframes appNoticeAn {
    0% {
        transform: translateX(0px) scale(0.5);
    }
    100% {
        transform: translateX(calc(-100vw - 100%)) scale(0.5);
    }
}

.viewer-footer {
    overflow: visible !important;
}
.viewer-toolbar > ul {
    overflow: visible !important;
}
.viewer-custom {
    position: relative;
    overflow: visible !important;
    font-size: 14px;
    line-height: 24px;
    color: white;
    &::before {
        content: " ";
        transition: all 0.2s;
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        margin: 0px !important;
        background-color: rgba(0, 0, 0, 1);
        z-index: -1;
        transform: translateX(-50%) rotate(45deg);
        left: 50%;
        top: -86%;
        opacity: 0;
    }
    &::after {
        content: "查询证伪";
        transition: all 0.2s;
        padding: 6px;
        display: block;
        position: absolute;
        border-radius: 4px;
        top: -142%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        opacity: 0;
        color: white;
        font-size: 14px;
        line-height: 100%;
        background-color: rgba(0, 0, 0, 1);
        direction: rtl;
        text-align: left;
    }
    &:hover {
        &::after {
            opacity: 1;
        }
        &::before {
            opacity: 1;
        }
    }
}
[lazy="loading"] {
    animation: an 2s linear infinite;
    @keyframes an {
        0% {
            opacity: 0;
            // clip-path: polygon();
            -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
        }
        100% {
            opacity: 1;
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
    }
}
</style>
