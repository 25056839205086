/**
 *
 * @returns 返回用户的设备
 */
export default function () {
    var userAgent = navigator.userAgent;

    // 正则表达式匹配常见移动设备浏览器信息
    var mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

    if (mobileRegex.test(userAgent)) {
        // 移动设备
        return "Mobile";
    } else {
        // PC端
        return "PC";
    }
}
