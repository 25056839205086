<template>
    <div class="main" v-if="allList.length">
        <div>
            <el-row class="elRow oneRow">
                <el-col
                    :span="6"
                    v-for="item in [
                        { name: '7*24小时服务', style: 'top: -1045px;left: -510px;' },
                        { name: '100%定制开发', style: 'top: -1045px;left: -575px;' },
                        { name: '可随时突察进度', style: 'top: -1045px;left: -639px;' },
                        { name: '100%源码交付', style: 'top: -1103px;left: -510px;' },
                    ]"
                    :key="item.name"
                >
                    <div>
                        <img :style="item.style" src="http://iv.okvu.cn/vugw/img/vu1111.png" />
                    </div>
                    <span>{{ item.name }}</span>
                </el-col>
            </el-row>
            <hr />
            <el-row class="elRow">
                <el-col :span="5" style="text-align: left">
                    <ul>
                        <li style="line-height: 2" class="li1">售前咨询热线</li>
                        <li style="line-height: 2" class="liB">{{ getWebData().phone }}</li>
                        <li style="line-height: 2" class="li2">
                            <a v-if="getWebData().one_url" class="a_two" :href="'http://' + getWebData().one_url" target="_blank">
                                {{ getWebData().one }}
                            </a>
                            <a v-else class="a_two" style="cursor: default">{{ getWebData().one }}</a>
                        </li>
                        <li style="line-height: 2" class="li2">
                            <a v-if="getWebData().two_url" class="a_two" :href="'http://' + getWebData().two_url" target="_blank">
                                {{ getWebData().two }}
                            </a>
                            <a v-else class="a_two" style="cursor: default">{{ getWebData().two }}</a>
                        </li>
                        <li style="line-height: 2" class="li2">
                            <a
                                v-if="getWebData().three_url"
                                class="a_two"
                                :href="'http://' + getWebData().three_url"
                                target="_blank"
                            >
                                {{ getWebData().three }}
                            </a>
                            <a v-else class="a_two" style="cursor: default">
                                {{ getWebData().three }}
                            </a>
                        </li>
                        <li style="line-height: 2" class="li2">
                            <a
                                v-if="getWebData().four_url"
                                class="a_two"
                                :href="'http://' + getWebData().four_url"
                                target="_blank"
                            >
                                {{ getWebData().four }}
                            </a>
                            <a v-else class="a_two" style="cursor: default">{{ getWebData().four }}</a>
                        </li>
                    </ul>
                    <p style="margin-top: 40px">
                        <a
                            v-if="getWebData().five_url"
                            :href="'http://' + getWebData().five_url"
                            target="_blank"
                            style="color: white"
                        >
                            {{ getWebData().five }}
                        </a>
                        <a v-else style="color: white; cursor: default">{{ getWebData().five }}</a>
                    </p>
                    <el-row class="wxIcon" style="padding-top: 40px; padding-bottom: 20px">
                        <el-col :span="8">
                            <div class="Icons" v-if="ll[0]">
                                <div class="vyerweima">
                                    <img :src="ll[0].image" />
                                </div>
                                <div class="box">
                                    <img
                                        class="bottomIcon"
                                        :src="ll[0].icon"
                                        :style="{ cursor: ll[0].url ? 'pointer' : 'default' }"
                                        @click="openNewPage(ll[0].url)"
                                    />
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="Icons" v-if="ll[1]">
                                <div class="vyerweima">
                                    <img :src="ll[1].image" />
                                </div>
                                <div class="box">
                                    <img
                                        class="bottomIcon"
                                        :src="ll[1].icon"
                                        :style="{ cursor: ll[1].url ? 'pointer' : 'default' }"
                                        @click="openNewPage(ll[1].url)"
                                    />
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="Icons" v-if="ll[2]">
                                <div class="vyerweima">
                                    <img :src="ll[2].image" />
                                </div>
                                <div class="box">
                                    <img
                                        class="bottomIcon"
                                        :src="ll[2].icon"
                                        :style="{ cursor: ll[2].url ? 'pointer' : 'default' }"
                                        @click="openNewPage(ll[2].url)"
                                    />
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="18" style="border-left: 1px solid #fff; color: hsla(0, 0%, 100%, 0.7)">
                    <!-- 固定6列数 -->
                    <el-row>
                        <el-col v-for="el in allList.slice(0, 6)" :key="el.id" :span="4" v-show="el.status" style="color: #fff">
                            {{ el.title }}
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col v-for="el in allList.slice(0, 6)" :key="el.id" :span="4" class="rightButtons">
                            <div v-for="item in el.children" :key="item.id" v-show="item.status">
                                <!-- 有富文本 -->
                                <router-link v-if="item.RichText" :to="`/info/${item.id}`" class="QRcode">
                                    <div v-if="item.erweima"><img :src="item.erweima" /></div>
                                    <span v-if="!item.image">{{ item.title }}</span>
                                    <img v-if="item.image" :src="item.image" />
                                </router-link>
                                <!-- 无富文本 -->
                                <a v-else target="_blank" :href="item.path" class="QRcode">
                                    <div v-if="item.erweima"><img :src="item.erweima" /></div>
                                    <span v-if="!item.image">{{ item.title }}</span>
                                    <img v-if="item.image" :src="item.image" />
                                </a>
                            </div>
                        </el-col> </el-row
                ></el-col>
            </el-row>
            <hr />
            <!-- 不固定行数 -->
            <el-row class="elRow qxpp" v-for="(el, i) in allList.slice(6, allList.length)" :key="el.id" v-show="el.status">
                <el-col :span="1" class="title" :style="{ color: i == 0 ? '#fff' : 'hsla(0, 0%, 100%, 0.4)' }">
                    {{ el.title }}
                </el-col>
                <el-col :span="22" class="oxx">
                    <div v-for="item in el.children" :key="item.id" v-show="item.status">
                        <!-- 有富文本 -->
                        <router-link
                            v-if="item.RichText"
                            :to="`/info/${item.id}`"
                            class="QRcode"
                            :style="{ color: i == 0 ? '#fff' : 'hsla(0, 0%, 100%, 0.4)' }"
                        >
                            <div v-if="item.erweima"><img :src="item.erweima" /></div>
                            <span v-if="!item.image">{{ item.title }}</span>
                            <img v-if="item.image" :src="item.image" />
                        </router-link>
                        <!-- 无富文本 -->
                        <a
                            v-else
                            target="_blank"
                            :href="item.path"
                            class="QRcode"
                            :style="{ color: i == 0 ? '#fff' : 'hsla(0, 0%, 100%, 0.4)' }"
                        >
                            <div v-if="item.erweima"><img :src="item.erweima" /></div>
                            <span v-if="!item.image">{{ item.title }}</span>
                            <img v-if="item.image" :src="item.image" />
                        </a>
                    </div>
                </el-col>
            </el-row>
            <!-- 都在搜 -->
            <el-row class="elRow dou" style="color: rgba(255, 255, 255, 0.4)">
                <el-col :span="1">都在搜</el-col>
                <el-col :span="22">
                    <div class="list">
                        <div v-for="el in KeywordBottom" :key="el.id">{{ el.key_word }}</div>
                    </div>
                </el-col>
            </el-row>
            <hr style="width: 90%; margin: 20px auto; opacity: 0.5" />
            <div class="footer elRow" style="text-align: left">
                <template v-for="el in newList">
                    <a :key="el.id" @click="openNewPage(el.url)" v-if="el.status">
                        <img v-if="el.image" :src="el.image" />
                        <span>{{ el.title }}</span>
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "WebBottom",
    inject: ["getWebData"],
    data() {
        return {
            allList: [],
            KeywordBottom: [],
            newList: [],
            ll: [],
        };
    },
    async created() {
        let { data } = await axios.get("/api/brand_and_bottom/show");
        this.getKeywordBottom();
        this.getNewList();
        this.getLlvy();
        this.allList = data.map(e => {
            return {
                title: e.title,
                id: e.id,
                status: e.status,
                children: e.children.map(el => {
                    return {
                        id: el.id,
                        // 图片文字图片地址
                        image: el.text_image,
                        // 二维码图片地址
                        erweima: el.qrcode_image,
                        // 富文本
                        RichText: el.details == "<p><br></p>" ? "" : el.details,
                        // 官网地址
                        path: el.url,
                        // 文字
                        title: el.title,
                        // 状态
                        status: el.status,
                    };
                }),
            };
        });
    },
    methods: {
        // 打开新页面
        openNewPage(url = "") {
            if (!url.replaceAll(" ", "")) return;
            url = url.replace("https://", "").replace("http://", "");
            window.open("http://" + url);
        },
        // 获取都在搜
        getKeywordBottom() {
            axios.get("/api/key_word/bottom").then(({ data }) => {
                this.KeywordBottom = data;
            });
        },
        // 获取新的底部链接
        getNewList() {
            axios.get("/api/website/bottomlist").then(({ data }) => {
                this.newList = data;
            });
        },
        async getLlvy() {
            let { data } = await axios.get("api/website/llvy_show", { params: { tag: 1 } });
            this.ll = data;
        },
    },
    computed: {
        dataList() {
            if (!this.allList.length) return {};
            return (id = 0) => this.allList.find(e => e.id == id);
        },
    },
};
</script>

<style lang="less" scoped>
a:hover {
    color: #7f88f2 !important;
}
.a_two {
    color: rgba(255, 255, 255, 0.4);
}
.Icons {
    position: relative;
    &:hover {
        .vyerweima {
            opacity: 1;
            z-index: 5;
        }
    }
    .vyerweima {
        // display: none;
        opacity: 0;
        transition: 0.5s;
        position: absolute;
        top: -160px;
        left: 50%;
        transform: translate(-50%);
        padding: 10px;
        background-color: white;
        border-radius: 5px;
        z-index: -1;
        & > img {
            width: 120px;
            height: 120px;
            object-fit: contain;
        }
    }
    .box {
        width: 45px;
        height: 45px;
        position: relative;
        overflow: hidden;
        margin: 0 auto;
        .bottomIcon {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.QRcode {
    display: block;
    position: relative;
    text-align: center;
    // color: white;
    // overflow: hidden;
    // background-color: white;
    height: 20px;
    width: 100%;
    & > div {
        position: absolute;
        opacity: 0;
        transition: 0.5s;
        top: -140px;
        left: 50%;
        transform: translate(-50%);
        padding: 10px;
        background-color: white;
        border-radius: 5px;
        z-index: -1;
        & > img {
            width: 100px;
            height: 100px;
        }
    }
    // 图片文字
    & > img {
        // width: 100%;
        width: 70px;
        height: 30px;
        // height: 20px;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // height: 25px;
    }
    &:hover {
        & > div {
            z-index: 5;
            opacity: 1;
        }
    }
}
// 第一层row
.elRow {
    width: 90%;
    margin: 0 auto;
}
.main {
    background-color: rgb(21, 27, 38);
    & > div {
        width: 100%;
        font-size: 14px;
        max-width: 2560px;
        margin: 0 auto;
        & > hr:nth-of-type(1) {
            width: 90%;
            margin: 30px auto;
            font-size: 10px;
            opacity: 0.5;
        }
        & > hr:nth-of-type(2) {
            width: 90%;
            margin: 10px auto;
            opacity: 0.5;
        }
    }
}
.oneRow {
    text-align: left;
    & > div {
        margin-top: 50px;
        & > div {
            overflow: hidden;
            width: 70px;
            height: 56px;
            position: relative;
            display: inline-block;
            img {
                opacity: 0.8;
                width: 703px;
                position: absolute;
            }
        }
        & > span {
            color: white;
            font-size: 18px;
            position: relative;
            top: -20px;
        }
    }
}
.qxpp {
    position: relative;
    a {
        color: hsla(0, 0%, 100%, 0.4);
    }
    .title {
        text-align: left;
        margin-top: 10px;
        white-space: nowrap;
    }
    .oxx {
        display: flex;
        flex-wrap: wrap;
        & > div {
            width: 10%;
            font-size: 14px;
            white-space: nowrap;
            // margin-right: 10px;
            margin-top: 10px;
            display: inline-block;
        }
    }
}
.dou {
    margin-top: 10px;
    & > div {
        text-align: left;
    }
    .list {
        display: flex;
        align-items: center;
        text-align: center;

        & > div {
            width: 10%;
        }
    }
}
// ---------------------------------------------------------------------------------------------------------------------
.footer {
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 20px;
        // margin-bottom: 10px;
        color: #73767d;
        font-size: 11px;
        cursor: pointer;
        white-space: nowrap;
        line-height: 20px;
        height: 20px;
        img {
            width: 18px;
            height: 18px;
            vertical-align: top;
            margin-right: 4px;
        }
        &:hover {
            color: #7f88f2;
        }
    }
}

.rightButtons {
    & > div {
        padding: 10px 0;
    }
    a {
        color: rgba(255, 255, 255, 0.8);
    }
    a:hover {
        color: #7f88f2;
    }
}
.li2 {
    color: hsla(0, 0%, 100%, 0.4);
}
.li1 {
    color: #fff;
}
.liB {
    color: #fff;
    font-size: 24px;
    margin: 20px 0;
}

.webBottomBox {
    width: 100%;
    margin: 0 auto;
    height: 500px;
    background-color: #fff;
    // 大图片
    .wbBigImg {
        width: 100%;
        height: 75%;
        img {
            width: 100%;
            height: 100%;
        }
    }
    // 底部信息
    .wbContent {
        width: 1150px;
        height: 25%;
        margin: 0 auto;
        // 上部分
        .wbcTop {
            width: 100%;
            height: 30%;
            border-bottom: 1px solid gainsboro;
            .wtP {
                min-width: 55px;
                height: 50%;
                margin-top: 10px;
                float: left;
                font-size: 15px;
                padding-left: 10px;
                cursor: pointer;

                position: relative;
                a {
                    color: black;
                }
                .wtCode {
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    top: -120px;
                    left: -10px;
                    display: none;
                    img {
                        border-radius: 10px;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .wtP:nth-child(1) {
                border-right: 1px solid gainsboro;
            }
            .wtP:hover {
                color: #7781f1;
                a {
                    color: #7781f1;
                }
                .wtCode {
                    display: block;
                }
            }
        }
        // 下部分
        .wbcBottom {
            width: 100%;
            height: 40%;
            padding-left: 10px;
            .wbLeft {
                width: 350px;
                height: 50%;
                float: left;
                line-height: 25px;
                margin-top: 15px;
                font-size: 15px;
                li {
                    width: 20%;
                    height: 100%;
                    float: left;
                    cursor: pointer;
                    text-align: center;
                }
                li:nth-child(5) {
                    border-right: 1px solid gainsboro;
                    box-sizing: border-box;
                }
                li:hover {
                    color: #7781f1;
                }
            }
            .wbRight {
                width: 600px;
                height: 50%;
                font-size: 15px;
                margin-left: 50px;
                margin-top: 15px;
                line-height: 25px;
                float: left;
                .wrYear {
                    float: left;
                }
                .wrCompany {
                    float: left;
                    cursor: pointer;
                }
                .wrCompany:hover {
                    color: #7781f1;
                }
            }
        }
        // 备案号
        .record {
            width: 100%;
            height: 20%;
            padding-left: 10px;
            line-height: 25px;
            font-size: 15px;
            a {
                color: black;
            }
        }
        .record:hover {
            a {
                color: #7781f1;
            }
        }
    }
}
</style>
